import axios, { AxiosError } from 'axios'
import { authActions } from '../app/auth'
import { store } from '../app/store'

const errorInterceptor = () =>
  axios.interceptors.response.use(
    (response) => response,
    async (err: AxiosError) => {
      if (err.status === 401) {
        store.dispatch(authActions.reset())
      }
      return Promise.reject(err)
    }
  )

export default errorInterceptor
