import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  startJobModalIsOpen: false,
  jobNavigateModalIsOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setStartJobModalIsOpen(state, action: PayloadAction<boolean>) {
      state.startJobModalIsOpen = action.payload;
    },
    setJobNavigateModalIsOpen(state, action: PayloadAction<boolean>) {
      state.jobNavigateModalIsOpen = action.payload;
    },
  },
});

export const modalActions = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
