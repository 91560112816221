export const jobListData = [
  {
    isActive: true,
    jobType: 'pickup',
    location: '15 montgomery road,Yaba..',
    deliverTo: 'Matt Dickson',
    startTime: '3:00 PM',
    endTime: '3:20 PM',
    deliveryDate: 'Thu,Oct 27',
    status: 'very-late',
    lateBy: '20:00',
    dropoffContact: '(+234)-070-8786-4281',
    package: {
      id: '#D20462',
      type: ' Electronics',
      weight: '20kg',
      value: '#4,000',
    },
  },
  {
    isActive: false,
    jobType: 'p_and_d',
    location: '15 ajayi bembe street, Yaba..',
    deliverTo: 'Emmanuel Obi',
    startTime: '4:00 PM',
    endTime: '4:25 PM',
    deliveryDate: 'Thu,Oct 27',
    status: 'running-late',
    lateBy: '5:00',
    dropoffContact: '(+234)-080-8826-3381',
    package: {
      id: '#D10426',
      type: 'Food',
      weight: '5kg',
      value: '#3,000',
    },
  },
  {
    isActive: false,
    jobType: 'dropoff',
    location: '20 adeojo street,Ikeja..',
    deliverTo: 'James Richards',
    startTime: '5:00 PM',
    endTime: '5:20 PM',
    deliveryDate: 'Thu,Oct 27',
    status: 'on-time',
    dropoffContact: '(+234)-070-8786-4281',
    package: {
      id: '#D11372',
      type: 'Fragile',
      weight: '15kg',
      value: '#14,000',
    },
  },
]

export const jobData = [
  {
    isActive: true,
    approved_by: 'Demilade,',
    auto_asign_rider: 'false,',
    created_at: '2023-03-07T11:29:06.961932,',
    delivery_day: '2023-03-07,',
    delivery_priority: 'flash,',
    dispatcher_id: '32YU23,',
    dropoff_arrival_time: '2023-03-06T12:25:40.516537,',
    dropoff_completed: 'false,',
    dropoff_departure_time: 'null,',
    dropoff_window_lower_limits: 'null,',
    dropoff_window_upper_limits: 'null,',
    id: '1a48ca54-8c89-41bb-bf6d-89317c44146d,',
    is_illegal: 'false,',
    is_urgent: 'false,',
    item_category: 'Clothes,',
    item_note: 'This package is was carefully assemble.,',
    item_quantity: '1,',
    item_value: 'Less than N1,000,',
    item_weight: '10,',
    job_creation_mode: 'manual,',
    job_type: 'pickup,',
    order_id: 'MT-79789420,',
    order_name: 'Lekki slot pickup,',
    package_size: 'small,',
    paid: 'true,',
    person_with_package: 'null,',
    pickup_arrival_time: '2023-03-06T12:25:40.536530,',
    pickup_completed: 'false,',
    pickup_departure_time: '2023-03-07T11:32:21.597668,',
    pickup_window_lower_limits: '2022-10-25T10:23:49.598000,',
    pickup_window_upper_limits: '2022-10-25T10:23:49.598000,',
    receiver_address: 'null,',
    receiver_address_type: 'null,',
    receiver_city: 'null,',
    receiver_country: 'null,',
    receiver_email: 'null,',
    receiver_latitude: 'null,',
    receiver_longitude: 'null,',
    receiver_name: 'null,',
    receiver_phone: 'null,',
    receiver_state: 'null,',
    route: 'null,',
    sender_address: '1 Hacker way,',
    sender_address_type: 'home,',
    sender_city: 'Mushin,',
    sender_country: 'Nigeria,',
    sender_email: 'yemi@roadpreppers.com,',
    sender_latitude: '3.3515,',
    sender_longitude: '6.6018,',
    sender_name: 'Olamide Badoo,',
    sender_phone: '+2348115554421,',
    sender_state: 'Lagos,',
    status: 'unassigned,',
    updated_at: '2023-03-07T11:29:06.961937,',
    user_id: 'f993e552-ccd2-46c7-9929-7cf070b75fa2,',
  },
  {
    isActive: false,
    approved_by: 'Demilade,',
    auto_asign_rider: 'false,',
    created_at: '2023-03-07T11:03:24.727255,',
    delivery_day: '2023-03-07,',
    delivery_priority: 'flash,',
    dispatcher_id: '32YU23,',
    dropoff_arrival_time: '2023-03-06T12:25:40.537537,',
    dropoff_completed: 'false,',
    dropoff_departure_time: 'null,',
    dropoff_window_lower_limits: '2022-10-25T10:23:49.598000,',
    dropoff_window_upper_limits: '2022-10-25T10:23:49.598000,',
    id: '596da30b-6466-47ff-9795-ff29f98178cd,',
    is_illegal: 'false,',
    is_urgent: 'false,',
    item_category: 'Clothes,',
    item_note: 'This package is was carefully assemble.,',
    item_quantity: '1,',
    item_value: 'Less than N1,000,',
    item_weight: '10,',
    job_creation_mode: 'manual,',
    job_type: 'pickup_and_dropoff,',
    order_id: 'MT-79789420,',
    order_name: 'Lekki slot pickup,',
    package_size: 'small,',
    paid: 'true,',
    part: 'pickup,',
    person_with_package: 'Oluwa Burna,',
    pickup_arrival_time: '2023-03-06T12:25:40.536534,',
    pickup_completed: 'false,',
    pickup_departure_time: '2023-03-07T11:32:21.597668,',
    pickup_window_lower_limits: '2022-10-25T10:23:49.598000,',
    pickup_window_upper_limits: '2022-10-25T10:23:49.598000,',
    receiver_address: '1 Hacker way,',
    receiver_address_type: 'home,',
    receiver_city: 'Mushin,',
    receiver_country: 'Nigeria,',
    receiver_email: 'yemi@roadpreppers.com,',
    receiver_latitude: '3.3515,',
    receiver_longitude: '6.6018,',
    receiver_name: 'Lotana Folarin,',
    receiver_phone: '+2348115554421,',
    receiver_state: 'Lagos,',
    route: 'null,',
    sender_address: '1 Hacker way,',
    sender_address_type: 'home,',
    sender_city: 'Mushin,',
    sender_country: 'Nigeria,',
    sender_email: 'yemi@roadpreppers.com,',
    sender_latitude: '3.3515,',
    sender_longitude: '6.6018,',
    sender_name: 'Olamide Badoo,',
    sender_phone: '+2348115554421,',
    sender_state: 'Lagos,',
    status: 'unassigned,',
    updated_at: '2023-03-07T11:03:24.727256,',
    user_id: 'f993e552-ccd2-46c7-9929-7cf070b75fa2,',
  },
  {
    isActive: false,
    approved_by: 'Demilade,',
    auto_asign_rider: 'false,',
    created_at: '2023-03-07T11:29:04.695255,',
    delivery_day: '2023-03-07,',
    delivery_priority: 'flash,',
    dispatcher_id: '32YU23,',
    dropoff_arrival_time: '2023-03-06T12:25:40.526537,',
    dropoff_completed: 'false,',
    dropoff_departure_time: 'null,',
    dropoff_window_lower_limits: 'null,',
    dropoff_window_upper_limits: 'null,',
    id: '1c8ef6c9-1a75-42e9-b74e-be23c7c3a18e,',
    is_illegal: 'false,',
    is_urgent: 'false,',
    item_category: 'Clothes,',
    item_note: 'This package is was carefully assemble.,',
    item_quantity: '1,',
    item_value: 'Less than N1,000,',
    item_weight: '10,',
    job_creation_mode: 'manual,',
    job_type: 'pickup,',
    order_id: 'MT-79789420,',
    order_name: 'Lekki slot pickup,',
    package_size: 'small,',
    paid: 'true,',
    person_with_package: 'null,',
    pickup_arrival_time: '2023-03-06T12:25:40.536531,',
    pickup_completed: 'false,',
    pickup_departure_time: '2023-03-07T11:32:21.597668,',
    pickup_window_lower_limits: '2022-10-25T10:23:49.598000,',
    pickup_window_upper_limits: '2022-10-25T10:23:49.598000,',
    receiver_address: 'null,',
    receiver_address_type: 'null,',
    receiver_city: 'null,',
    receiver_country: 'null,',
    receiver_email: 'null,',
    receiver_latitude: 'null,',
    receiver_longitude: 'null,',
    receiver_name: 'null,',
    receiver_phone: 'null,',
    receiver_state: 'null,',
    route: 'null,',
    sender_address: '1 Hacker way,',
    sender_address_type: 'home,',
    sender_city: 'Mushin,',
    sender_country: 'Nigeria,',
    sender_email: 'yemi@roadpreppers.com,',
    sender_latitude: '3.3515,',
    sender_longitude: '6.6018,',
    sender_name: 'Olamide Badoo,',
    sender_phone: '+2348115554421,',
    sender_state: 'Lagos,',
    status: 'unassigned,',
    updated_at: '2023-03-07T11:29:04.695258,',
    user_id: 'f993e552-ccd2-46c7-9929-7cf070b75fa2,',
  },
]
