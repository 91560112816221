import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Loader from '../components/Loader/Loader'
import { APP, COMPLETETASK, LOGIN, VERIFY } from './routeConstants'

const Main = lazy(() => import('../pages/in-app/Main'))
const CompleteTask = lazy(
  () => import('../pages/online/SingleJobPage/CompleteTask')
)
const VerifyCode = lazy(() => import('../pages/onboarding/VerifyCode'))
const Login = lazy(() => import('../pages/onboarding/Login'))

const RouteDom = () => {
  return (
    <div style={{ maxWidth: '500px', margin: 'auto', height: '100%' }}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={`${APP}/*`} element={<Main />} />
          <Route path={COMPLETETASK} element={<CompleteTask />} />
          <Route path={`/${LOGIN}/${VERIFY}`} element={<VerifyCode />} />
          <Route path={`/${LOGIN}`} element={<Login />} />
          <Route path='/' element={<Navigate to={`${APP}`} />} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default RouteDom
