import React, { useState } from 'react'
import { SiteAssets } from '../../assets'
import classes from './loader.module.scss'
import { motion } from 'framer-motion'

const Loader = () => {
  const [showText, setShowText] = useState(false)

  setTimeout(() => {
    setShowText(true)
  }, 3000)

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loader}>
        <div className={classes.loader_inner}>
          <img src={SiteAssets.motionsLogo} alt='' />
          <div></div>
        </div>
      </div>

      {showText && (
        <motion.p
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}>
          Please wait a moment...
        </motion.p>
      )}
    </div>
  )
}

export default Loader
