import { Toaster } from 'react-hot-toast'
import './App.scss'
import { interceptors } from './interceptors'
import RouteDom from './routing/Routes'

interceptors.errorInterceptor()

function App() {
  return (
    <>
      <RouteDom />
      <Toaster />
    </>
  )
}

export default App
