import { authReducer } from './auth'
import { jobReducer } from './features/job-slice'
import { modalReducer } from './features/modal-slice'
import { jobsReducer } from './features/jobs'

export const reducers = {
  auth: authReducer,
  jobs: jobReducer,
  job: jobsReducer,
  modal: modalReducer,
}
