import motionsLogo from './images/Group 4.svg'
import naijIcon from './images/naij.svg'
import ovalIcon from './images/Oval.svg'
import locationIcon from './images/location.svg'
import clockIcon from './images/clock.svg'
import currentIcon from './images/current.svg'
import blackClock from './images/bclock.svg'
import dropOffIcon from './images/dropoff.svg'
import rightCaret from './images/rCaret.svg'
import locateIcon from './images/locate.svg'
import lockersImg from './images/lockers.svg'
import backIcon from './images/back.svg'
import userIcon from './images/user.svg'
import onTime from './images/on-time.svg'
import runningLate from './images/running-late.svg'
import veryLate from './images/very-late.svg'
import fileIcon from './images/file.svg'
import hookIcon from './images/hook.svg'
import phoneIcon from './images/phone.svg'
import angryEmoji from './images/angryEmoji.svg'
import questionMark from './images/questionMark.svg'
import shoppingBag from './images/shop-bag.svg'
import redClose from './images/red-close.svg'
import greenTick from './images/green-mark.svg'
import addPhoto from './images/add-photo.svg'
import signatureIcon from './images/signature.svg'
import plane from './images/direction.svg'
import checkFilled from './images/check filled.svg'
import check from './images/check.svg'
import dropdown from './images/dropdown.svg'

export const SiteAssets = {
  motionsLogo,
  naijIcon,
  ovalIcon,
  locationIcon,
  currentIcon,
  clockIcon,
  blackClock,
  dropOffIcon,
  rightCaret,
  locateIcon,
  lockersImg,
  backIcon,
  userIcon,
  onTime,
  fileIcon,
  hookIcon,
  phoneIcon,
  runningLate,
  veryLate,
  angryEmoji,
  questionMark,
  shoppingBag,
  redClose,
  greenTick,
  addPhoto,
  signatureIcon,
  plane,
  checkFilled,
  check,
  dropdown,
}
