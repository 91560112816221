import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './app/theme'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { interceptors } from './interceptors'
import ReactGA from 'react-ga4'
import { configSetting } from './config'
import TagManager from 'react-gtm-module'

const container = document.getElementById('root')!
const root = createRoot(container)

interceptors.authInterceptor()

TagManager.initialize({
  gtmId: 'GTM-TZZCQVBQ',
})

ReactGA.initialize(configSetting.gaMeasurementKey)

ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname,
})

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<h1>Sorry, There was an error</h1>}>
      <Provider store={store}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
