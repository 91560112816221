import axios from 'axios'
import { store } from '../app/store'

const authInterceptor = () =>
  axios.interceptors.request.use(
    (request) => {
      const fromStore = store.getState().auth.rider

      if (fromStore && fromStore.access_token && request.headers) {
        request.headers.Authorization = `Bearer ${fromStore.access_token}`
      }

      return request
    },
    (error) => Promise.reject(error)
  )

export default authInterceptor
