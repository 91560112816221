// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthResponse } from '../models/auth'

export interface LocationType {
  longitude: number
  latitude: number
}

const initialState: {
  rider: AuthResponse
  online: boolean
  location: LocationType | null
  hasLocationAccess: boolean
} = {
  rider: {
    phone: '',
  },
  online: false,
  location: null,
  hasLocationAccess: true,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication(state, action: PayloadAction<AuthResponse>) {
      state.rider = { ...state.rider, ...action.payload }
    },
    setPhone(state, action: PayloadAction<string>) {
      state.rider = { ...state.rider, phone: action.payload }
    },
    setOnline(state, action: PayloadAction<boolean>) {
      state.online = action.payload
    },
    setLocation(state, action: PayloadAction<LocationType | null>) {
      state.location = action.payload
    },
    setLocationAccess(state, action: PayloadAction<boolean>) {
      state.hasLocationAccess = action.payload
    },
    reset: () => initialState,
  },
})

export const authActions = auth.actions
export const authReducer = auth.reducer
