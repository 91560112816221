import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Job } from '../../models/jobs'

interface JobType {
  jobs: Job[]
  loading: boolean
  activeTab: number
  job: Job | null
}

const initialState: JobType = {
  jobs: [],
  loading: false,
  activeTab: 0,
  job: null,
}

export const jobs = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobs(state, action: PayloadAction<Job[]>) {
      state.jobs = action.payload
    },
    setloading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setActiveTab(state, action: PayloadAction<number>) {
      state.activeTab = action.payload
    },
    setJob(state, action: PayloadAction<Job | null>) {
      state.job = action.payload
    },
  },
})

export const jobsActions = jobs.actions
export const jobsReducer = jobs.reducer
