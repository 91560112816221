import { createSlice } from '@reduxjs/toolkit';
import { jobData, jobListData } from '../data';

const initialState: any = {
  jobList: jobData,
  selectedJobId: null,
  jobStatus: null,
  signatureImage: '',
};

export const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobList(state, action) {
      state.jobList = action.payload;
    },
    setSelectedJob(state, action) {
      state.selectedJobId = action.payload;
    },
    setJobStatus(state, action) {
      state.jobStatus = action.payload;
    },
    setSignatureImage(state, action) {
      state.signatureImage = action.payload;
    },
  },
});

export const jobActions = jobSlice.actions;
export const jobReducer = jobSlice.reducer;
